import { FC, useMemo } from "react";
import { Icon } from "../../ui/Icon";
import { Fabric, FabricColor } from "../../../types";
import {
  showDisabledColorOption,
  showIconOnDisabledColorOptions,
} from "../config";
import clsx from "clsx";

interface ColorOptionProps {
  prefix: keyof FabricColor;
  option: Fabric;
  onClick: (prefix: keyof FabricColor, option: Fabric) => void;
  defaultOption?: FabricColor;
  disabled?: boolean;
  idx?: number;
}

export const ColorOption: FC<ColorOptionProps> = ({
  prefix,
  option,
  onClick,
  defaultOption,
  disabled,
  idx,
}) => {
  const isWhite = option?.data?.web_format === "#FFFFFF";
  const isSelected = useMemo(() => {
    const selectedOption =
      prefix === "main" ? defaultOption?.main : defaultOption?.contrast;
    return selectedOption?.id === option.id;
  }, [prefix, option.id, defaultOption]);

  const backgroundColor = option?.data?.web_format;

  const containerClasses = clsx(
    "relative group cursor-pointer w-6 h-6 rounded-full flex items-center justify-center",
    {
      border: isWhite,
      "opacity-25": disabled && showDisabledColorOption,
    },
  );

  const iconClass = isWhite ? "text-black" : "text-white";

  return (
    <div
      title={option?.data?.name}
      onClick={() => onClick(prefix, option)}
      className={containerClasses}
      style={{ backgroundColor }}
    >
      {isSelected && <Icon className={`mt-1 ${iconClass}`} icon="check" />}
      {disabled && showIconOnDisabledColorOptions && (
        <Icon className={`mt-1 ${iconClass}`} icon="close" />
      )}
      {!disabled && (
        <div
          className={clsx(
            "absolute bottom-full rounded left-1/2 transform -translate-x-1/2 w-16 h-16 shadow-lg opacity-0 scale-75",
            "group-hover:opacity-100 group-hover:scale-100 transition duration-200 ease-in-out",
            { "border border-gray-300": isWhite }, // Add a border if the color is white
            { "-translate-x-6": idx === 0 },
          )}
          style={{
            backgroundImage: `linear-gradient(to bottom, ${backgroundColor}, ${darkenColor(
              backgroundColor,
              0.1,
            )})`,
          }}
        />
      )}
    </div>
  );
};

const darkenColor = (color: string, amount: number): string => {
  if (!/^#[0-9A-F]{6}$/i.test(color)) {
    throw new Error("Invalid hex color format. Use #RRGGBB.");
  }

  const hexToRgb = (hex: string): [number, number, number] => {
    const bigint = parseInt(hex.slice(1), 16);
    return [bigint >> 16, (bigint >> 8) & 255, bigint & 255];
  };

  const rgbToHex = (r: number, g: number, b: number): string =>
    `#${((1 << 24) + (r << 16) + (g << 8) + b)
      .toString(16)
      .slice(1)
      .toUpperCase()}`;

  const adjustChannel = (channel: number): number =>
    Math.max(0, Math.min(255, Math.round(channel * (1 - amount))));

  const [r, g, b] = hexToRgb(color);

  return rgbToHex(adjustChannel(r), adjustChannel(g), adjustChannel(b));
};

export default ColorOption;
