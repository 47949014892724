import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../index";
import { CartProduct, Size, SizingObject } from "../../types";
import { getMininumOrderQuantity, totalCartProductAmount } from "../../utils";

interface CartState {
  products: CartProduct[];
}

const initialState: CartState = {
  products: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<CartProduct>) => {
      state.products = [...state.products, action.payload];
    },
    updateProduct: (
      state,
      action: PayloadAction<{ id: string; cartProduct: Partial<CartProduct> }>,
    ) => {
      const { id, cartProduct } = action.payload;
      // Find the index of the CartProduct to update
      const index = state.products.findIndex((item) => item.id === id);

      // Ensure the CartProduct exists
      if (index !== -1) {
        const newCartProduct = { ...state.products[index], ...cartProduct };
        // Update the CartProduct while keeping it at the same index
        state.products[index] = newCartProduct;
      }
    },
    removeFromCart: (state, action: PayloadAction<CartProduct>) => {
      const hasProduct = state.products?.find(
        (product) => product.id === action.payload.id,
      );
      if (hasProduct) {
        state.products = state.products?.filter(
          (product) => product.id !== action.payload.id,
        );
      }
    },
    updateAmount: (
      state,
      action: PayloadAction<{
        gender: string;
        id: string;
        size: any;
        amount: number;
      }>,
    ) => {
      const hasProduct = state.products?.find(
        (product) => product.id === action.payload.id,
      );
      if (hasProduct) {
        state.products = state.products?.map((product) => {
          if (product.id === action.payload.id) {
            const genderOption = product.amount?.find(
              (sizingObject: SizingObject) =>
                sizingObject.primary.translation_key === action.payload.gender,
            );
            const sizeOption = genderOption?.items.find(
              (optionSize: Size) => optionSize.size === action.payload.size,
            );
            sizeOption.amount = action.payload.amount;
          }
          return product;
        });
      }
    },
  },
});

export const { addToCart, removeFromCart, updateAmount, updateProduct } =
  cartSlice.actions;

export const selectCart = (state: RootState) => state.cart;
export const selectCartItems = (state: RootState) => state.cart.products;
export const selectLatestCartItem = (state: RootState) => {
  const cart = state.cart.products;
  return cart[cart.length - 1];
};

export const createIsCartValidSelector = (settings: any) =>
  createSelector(
    (state: RootState) => state.cart.products, // Access products from the state
    (products: CartProduct[]): boolean => {
      if (!products?.length) return false;

      const MINIMUM_TOTAL_QUANTITY = getMininumOrderQuantity(settings); // Overall minimum for products
      const MINIMUM_SIZINGOBJECT_QUANTITY = 25; // Minimum per sizing object if ordered

      return products.every((product) => {
        // Check if the total quantity of the product meets the overall minimum
        const totalQuantity = totalCartProductAmount(product);
        if (totalQuantity < MINIMUM_TOTAL_QUANTITY) {
          return false; // Product does not meet the total quantity requirement
        }

        // Check if each sizingObject meets the per-sizingObject minimum, skipping empty ones
        return product.amount?.every((sizingObject: SizingObject) => {
          const filteredItems = sizingObject.items.filter(
            (item) =>
              item.amount !== undefined &&
              item.amount !== null &&
              item.amount > 0,
          ); // Only consider items with a positive amount

          if (!filteredItems.length) {
            // Skip validation for empty sizing objects
            return true;
          }

          const sizingObjectTotal = filteredItems.reduce(
            (total, { amount }) => total + (amount ?? 0),
            0,
          );

          return sizingObjectTotal >= MINIMUM_SIZINGOBJECT_QUANTITY;
        });
      });
    },
  );

export default cartSlice.reducer;
